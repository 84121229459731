var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.7/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"stacktrace-header\">\n        <div class=\"warning-symbol\">\n            <i class=\"fa fa-exclamation-triangle\"></i>\n        </div>\n        <div class=\"message\"><strong>Error:</strong> "
    + container.escapeExpression((((helper = lookupProperty(helpers,"message") || container.strict(depth0, "message", {"start":{"line":6,"column":56},"end":{"line":6,"column":63}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":66}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(data,"first"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"more_info"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"stacktrace-content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"stackframes"),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":33,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"exit\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"stacktrace-more-info\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"more_info") || container.strict(depth0, "more_info", {"start":{"line":12,"column":44},"end":{"line":12,"column":53}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"more_info","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":55}}}) : helper)))
    + "</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div data-full-path=\""
    + alias4((((helper = lookupProperty(helpers,"full_path") || alias1(depth0, "full_path", {"start":{"line":16,"column":36},"end":{"line":16,"column":45}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"full_path","hash":{},"data":data,"loc":{"start":{"line":16,"column":33},"end":{"line":16,"column":48}}}) : helper)))
    + "\" data-line-no=\""
    + alias4((((helper = lookupProperty(helpers,"line_number") || alias1(depth0, "line_number", {"start":{"line":16,"column":67},"end":{"line":16,"column":78}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":16,"column":64},"end":{"line":16,"column":81}}}) : helper)))
    + "\">\n                <div class=\"stackframe\">\n                    <i class=\"fa fa-caret-right expand\"></i>\n                    <span class=\"subtle\">at</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"function_name"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + "                    <span class=\"subtle\">"
    + alias4((((helper = lookupProperty(helpers,"show_path") || alias1(depth0, "show_path", {"start":{"line":23,"column":44},"end":{"line":23,"column":53}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"show_path","hash":{},"data":data,"loc":{"start":{"line":23,"column":41},"end":{"line":23,"column":56}}}) : helper)))
    + ":"
    + alias4((((helper = lookupProperty(helpers,"line_number") || alias1(depth0, "line_number", {"start":{"line":23,"column":60},"end":{"line":23,"column":71}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":23,"column":57},"end":{"line":23,"column":74}}}) : helper)))
    + "</span>\n                </div>\n                <div class=\"code-context\" style=\"display: none\">\n                    <div class=\"code-context-content\">"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,lookupProperty(depth0,"context"),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":29,"column":35}}})) != null ? stack1 : "")
    + "</div>\n                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3(alias2(alias1(lookupProperty(depth0,"function_name"), "scope", {"start":{"line":21,"column":23},"end":{"line":21,"column":42}} ), depth0))
    + "<b>"
    + alias3(alias2(alias1(lookupProperty(depth0,"function_name"), "name", {"start":{"line":21,"column":51},"end":{"line":21,"column":69}} ), depth0))
    + "</b>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"focus"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":33},"end":{"line":28,"column":71}}})) != null ? stack1 : "")
    + "><span class=\"line-number\">"
    + alias4((((helper = lookupProperty(helpers,"line_number") || alias2(depth0, "line_number", {"start":{"line":28,"column":101},"end":{"line":28,"column":112}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":28,"column":98},"end":{"line":28,"column":115}}}) : helper)))
    + "</span> "
    + alias4((((helper = lookupProperty(helpers,"line") || alias2(depth0, "line", {"start":{"line":28,"column":126},"end":{"line":28,"column":130}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"line","hash":{},"data":data,"loc":{"start":{"line":28,"column":123},"end":{"line":28,"column":133}}}) : helper)))
    + "</div>";
},"10":function(container,depth0,helpers,partials,data) {
    return "class=\"focus-line\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"errors"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":9}}})) != null ? stack1 : "");
},"useData":true});